.ads-detail-inf {
    width: 100%;
    height: auto;
    /* background-color: #E3E6E8; */
    border: 1px solid #CDD1D5;
    border-radius: 8px;
}

.ads-detail-inf>.worker-detail-inf-row>.worker-detail-inf-row-title {
    background-color: #C7DCF5;
}

.img-container {
    max-width: 300px;
    height: 200px;
    background-color: #ebebeb;
}

.image-ads {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.image-list {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px
}