.dashboard {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    background-color: #E3E6E8;
    padding: 28px;
    /* width: max-content;
    height: max-content; */
    /* overflow: hidden; */
}

.dashboard-content {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    overflow: auto;
}

.dashboard-header {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    /* or 138% */
    color: #215493;
    margin-top: 5px;
    margin-left: 7px;
}

.dashboard-header p {
    margin: 0;
}

.dashboard-news-title {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    color: #1C1E21;
    /* margin-top: 24px; */
    margin-bottom: 16px;
}

.dashboard-news-container {
    width: calc(50% - 14px);
    ;
    align-items: flex-start;
    justify-content: flex-start;
}

.dashboard-news {
    justify-content: space-between;
    align-items: flex-start;
}

.dashboard-news-content {
    height: 200px;
    width: 100%;
    border: 1px solid #CDD1D5;
    border-radius: 12px;
}

.dashboard-news-content1 {
    height: 400px;
    overflow: hidden;
    overflow-y: auto;
}
.dashboard-news-content1::-webkit-scrollbar {
    width: 10px;
}
.dashboard-news-content1::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}
.dashboard-news-content1::-webkit-scrollbar-thumb {
    background: #bababa;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 10px 0;
}
.progress-chart {
    justify-content: center;
    align-items: center;
}

.dashboard-news-item {
    justify-content: space-between;
    align-items: center;
    /*height: 60px;*/
    margin: 0 68px 0 16px;
    width: 100%;
    /* border-bottom: 1px solid #F5F5F5; */
}

.custom-progress {
    display: inline-flex;
    width: 70%;
    justify-content: flex-start;
    align-items: center;
}

.title-progress {
    display: inline-block;
    width: 25%;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: #58606A;
}

label {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */
}

.progress-chart-item {
    width: 100%;
    height: 33.3%;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding-left: 16px;
}

.news-date {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 125% */
    display: flex;
    align-items: center;
    color: #1C1E21;
    margin-right: 20px;
}

.news-inf {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /* identical to box height, or 125% */
    display: flex;
    align-items: center;
    color: #406FE5;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    line-height: 24px;
}

.dashboard-warning-title {
    justify-content: space-between;
    align-items: center;
}

.notice {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;
    color: #F85825;
}

.dashboard-news-approval {
    width: 100%;
}

.table-header {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    color: #1C1E21;
}

.button-tb {
    padding: 4px 20px;
    gap: 8px;
    width: 72px;
    height: 32px;
    background: #215493;
    border: none;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 100;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin: 0;
    /* cursor: pointer; */
}

.text-overflow {
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* width: 100% */
}

.news-see-more {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #195192;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 116px;
    height: 32px;
    border: 1px solid rgba(33, 84, 147, 0.3);
    border-radius: 8px;
    margin: 24px 0 14px 0px;
}

.news-see-more-ctn {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.news-time {
    width: 120px !important;
}

.news-content-title {
    width: calc(100% - 120px);
}

.news-inf-content {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    overflow:hidden;
    display:inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 85px);
    font-family: 'Zen Kaku Gothic Antique';
    color: rgba(0, 0, 0, 0.7);
}
.news-inf-content2 {
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: break-spaces;
}

.news-inf-content-ctn {
    width: 100%;
}

.news-inf-content-show-more {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #195192;
    cursor: pointer;
    text-decoration: underline;
}

.empty-news-list {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #195192;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.card-slider {
    position: relative;
}
.slick-next {
    right: 8px !important;
    background-color: #999a99 !important;
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    border: 1px solid #ffffff !important;
    top: 42% !important;
}
.slick-prev {
    left: 8px !important;
    background: #999a99 !important;
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    z-index: 2;
    border: 1px solid #ffffff !important;
    top: 42% !important;
}
.slick-prev:before {
    display: none;
}
.slick-next:before {
    display: none;
}
.icon-arrow-slider {
    color: #ffffff;
    font-size: 16px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dashboard-slider {
    margin-top: 24px;
}
.btnOK {
    color: #ffffff;
    background: #215493;
    padding: 10px 18px;
    border-radius: 6px;
    margin-left: 6px;
}
.btnCancel {
    color: #215493;
    border: 1px solid #215493;
    padding: 10px 18px;
    border-radius: 6px;
    margin-right: 6px;
}
.custom-img {
    height: 200px !important;
}
