.height36 {
    height: 36px !important
}
.width-full {
    width: 100% !important;
    margin-right: 28px;
    margin-left: 0px !important;
    margin-top: 22px
}
.search-icon {
    font-family: 'FontAwesome';
}

#input-search::placeholder {
    color: #000;
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #000;
}
::-moz-placeholder { /* Firefox 19+ */
    color: #000;
}
:-ms-input-placeholder { /* IE 10+ */
    color: #000;
}
:-moz-placeholder { /* Firefox 18- */
    color: #000;
}

.project-list-search-select-style {
  width: 100% !important;
  height: 36px !important;
  padding: 0px !important;
  /* margin-right: 16px; */
}

.project-list-search-select-style .MuiOutlinedInput-root {
  height: 36px;
  padding: 0px !important;
  padding-left: 10px !important;
}

.project-list-search-select-style .autocomplete-custom-textfield input {
  border: none;
}

.project-list-search-select-style .MuiAutocomplete-root {
  height: 36px;
  padding: 0px !important;
}

.project-list-search-select-style fieldset {
  height: 42px !important;
  border-radius: 8px;
}