
.modal-create-plan {
    width: 399px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    justify-content: center;
    align-items: center;    
}
.modal-create-plan-title {
    width: 100%;
    height: 10%;
    justify-content: center;
    align-items: center;  
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1C1E21;
}
.modal-create-plan-form {
    width: 100%;
    height: 70%;
    justify-content: flex-start;
    align-items: center;    
}

.modal-create-plan-buttons {
    width: 100%;
    height: 20%;
    justify-content: space-between;
    align-items: center;    
    margin-top: 20px;
}
.input-add-plan {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}
.input-add-plan input{
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #CDD1D5;
    border-radius: 8px;
}

.input-add-plan textarea{
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 84px;
    background: #FFFFFF;
    border: 1px solid #CDD1D5;
    border-radius: 8px;
    resize:vertical;
}

.input-add-plan p{
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 12px
}
.dropdown-create-plan {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #FFFFFF;
}

.dropdown-control-plan {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #CDD1D5 !important;
    border-radius: 8px !important;
}

.modal-create-plan-button2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 190px;
    height: 44px;
    background: #215493;
    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color: #FFFFFF;
    outline: none;
    border: none
}
.modal-create-plan-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 190px;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid rgba(33, 84, 147, 0.3);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color:  #215493;;
}
.modal-create-plan-button-add {
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 8px;
    width: 100%;
    background: #215493;
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color: #FFFFFF;
    outline: none;
    border: none
}
.modal-dropdown-create-plan1 {
    width: calc(100%);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    height: 36px;
    background: #FFFFFF;
    font-family: "FontAwesome";
}

.select-search {
    width: 100% !important;
}

.select-search input {
    width: 100% !important;
}