.img-container-edit {
    max-width: 150px;
    height: 100px;
    position: relative;
    background-color: #ebebeb;
}

.image-ads-edit {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

.image-list-edit {
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 12px
}

.delete-file {
    color: red;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
}

.btn-upload {
    border: 1px solid #CDD1D5;
    color: white;
    background: #215493;
    border-radius: 8px;
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.add-file {
    font-size: 20px;
    margin-right: 12px;
}