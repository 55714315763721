.container-project-create-detail {
    /* width: calc(100% - 56px); */
    /* height: calc(100% - 120px); */
    /* height: 100%; */
    background-color: #E3E6E8;
    padding: 28px;
    /* overflow: hidden; */
    /* overflow-y: scroll; */
}

.project-create-content {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    width: calc(100%);
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    overflow-y: auto;
    justify-content: flex-start;
}
.create-project-button1 {
    width: 100%;
    /* height: 8%; */
    justify-content: space-between;
    align-items: flex-start;
}

.create-project-title1 {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #1C1E21;
}

.create-project-button2 {
    justify-content: center;
    align-items: center;
    border-color: #215493;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    padding: 10px 18px;
    gap: 8px;
    /* position: absolute; */
    width: 140px;
    height: 36px;
    border: 1px solid rgba(33, 84, 147, 0.4);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 6px;
    padding: 0
}

.dropdown-create-user2 {
    width: calc(100%);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    height: 36px;
    background: #FFFFFF;
    font-family: "FontAwesome";
}

.auto-height {
    margin-bottom: 12px;
    height: auto !important;
}

.target-datepicker-wrap {
    width: 100%;
}

.target-datepicker-wrap span {
   padding: 5px
}

.button-create-project-field {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
}

.create-project-input {
    width: 343px;
    /* height: 60px; */
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 12px;
}
.select-search {
    position: relative;
}

.select-search__input {
    width: 343px !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #FFFFFF;
    border: 1px solid #CDD1D5 !important;
    border-radius: 8px !important;
}


.select-search__options {
    list-style: none;
    padding: 0;
    position: absolute;
    z-index: 100;
    top: 36px;
    left: 0;
    width: 100% !important;
    max-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid #ccc;
    background-color: #FFFFFF;
}

.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

  /**
  * Option
  */
.select-search__option,
.select-search__not-found {
    display: block;
    height: 36px !important;
    width: 343px;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.project-create-multiple-select-style {
  width: 100%;
  min-height: 38px;
  height: auto;
  padding: 0px;
}

.project-create-multiple-select-style .MuiOutlinedInput-root {
  min-height: 38px;
  height: auto;
  padding: 0px !important;
  padding-left: 10px !important;
}

.project-create-multiple-select-style .autocomplete-custom-textfield input {
  border: none;
}

.project-create-multiple-select-style .MuiAutocomplete-endAdornment {
  display: none;
}

.project-create-multiple-select-style .MuiAutocomplete-root {
  height: 36px;
  padding: 0px !important;
}

.project-create-multiple-select-style fieldset {
  border-radius: 8px;
}

.select-search__not-found {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.react-datepicker-popper .react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon {
  padding: 0;
  font-size: 100%;
}