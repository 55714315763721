.container-company-create-detail {
    /* width: calc(100% - 56px);
    height: calc(100% - 120px); */
    width: 100%;
    height: 100%;
    background-color: #E3E6E8;
    padding: 28px;
    overflow: hidden;
}

.company-create-content {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    overflow: auto;
}

.create-company-input {
    width: 343px !important;
}
.checkbox-create-company .checkbox-text{
    margin: 0;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}
.create-button-company {
    justify-content: flex-start !important;
}

.client-company-create-search-select-style {
  width: 343px !important;
  height: 36px !important;
  padding: 0px !important;
}

.client-company-create-search-select-style .MuiOutlinedInput-root {
  height: 36px;
  padding: 0px !important;
  padding-left: 10px !important;
}

.client-company-create-search-select-style .autocomplete-custom-textfield input {
  border: none;
}

.client-company-create-search-select-style .MuiAutocomplete-root {
  height: 36px;
  padding: 0px !important;
}

.client-company-create-search-select-style fieldset {
  height: 42px !important;
  border-radius: 8px;
}